/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/system/useTheme';
import { AddFilterBtnTheme, AddNewBtnTheme } from '../rmsThemes/rmsThemes';
import moment from 'moment-timezone';
import type { EffectCallback } from 'react'
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { BreadCrumbObject } from './CustomBreadCrumbs/CustomBreadCrumbs';
import BreadCrumbsNavigations from '../layouts/breadCrumbsNavigationConstants'

interface ScreenSizeProp {
  width: number;
  height: number;
}
interface ScreenResolutionProp {
  result: boolean;
  val: string;
}
interface IHeaderActionButton {
  id: string;
  headerActionBtnClick: (event) => void;
  isAccessible?: boolean
  badgeCount?: number
}
export interface HeaderActionBtn {
  toolTipText: string;
  iconClassName: string;
  tooltipContainerClassName: string;
  buttonId: string;
  customTheme?: object;
  buttonText: string;
  btnClassName: string;
  handleClick: (event) => void;
  buttonVariant: string;
  isAccessible?: boolean
  badgeCount?: number
}

interface RelativeTimeForTableOptions {
  justNowText?: string;
  minuteAgoText?: string;
  minutesAgoText?: string;
  todayText?: string;
  yesterdayText?: string;
  olderDateText?: string;
}

export interface IBreadCrumb {
  objectType: 'link' | 'text'
  id: string,
  link?: string,
  btnText?: string
  linkBtnState?: object | null
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleOnClick?: Function
  text?: string
}

const HeaderActionBtns = [
  {
    toolTipText: 'Add',
    iconClassName: 'rms__table__dashboard__add__new__btn',
    tooltipContainerClassName: 'tooltip__icon__wrapper',
    buttonId: 'add_new_btn',
    customTheme: AddNewBtnTheme,
    buttonText: 'Add',
    btnClassName: 'add__new__btn',
    handleClick: (): void => { },
    buttonVariant: '',
  },

  {
    toolTipText: 'Set Param',
    iconClassName: 'rms__table__dashboard__add__new__btn',
    tooltipContainerClassName: 'tooltip__icon__wrapper',
    buttonId: 'set_param_btn',
    customTheme: AddNewBtnTheme,
    buttonText: 'Set Param',
    btnClassName: 'add__new__btn',
    handleClick: (): void => { },
    buttonVariant: '',
  },
  {
    toolTipText: 'Add Filter',
    iconClassName: 'rms__table__dashboard__add__filter__btn',
    tooltipContainerClassName: 'tooltip__icon__wrapper',
    buttonId: 'add_filter_btn',
    customTheme: AddFilterBtnTheme,
    buttonText: 'Filters',
    btnClassName: 'add__filter__btn',
    handleClick: (): void => { },
    buttonVariant: 'outlined',

  },

  {
    toolTipText: 'Export',
    iconClassName: 'rms__table__dashboard__export__csv__icon',
    tooltipContainerClassName: 'tooltip__icon__wrapper-export',
    buttonId: 'export_csv_btn',
    buttonText: 'Export',
    btnClassName: 'export__csv__btn',
    handleClick: (): void => {
      // onExportBtnClick?.()
    },
    buttonVariant: 'filled',
  },
  {
    toolTipText: 'Add Charger',
    iconClassName: 'rms__table__dashboard__add__new__btn',
    tooltipContainerClassName: 'tooltip__icon__wrapper',
    buttonId: 'add_new_charger',
    customTheme: AddNewBtnTheme,
    buttonText: 'Add Charger',
    btnClassName: 'add__new__btn',
    handleClick: (): void => { },
    buttonVariant: '',
  },
  {
    toolTipText: 'View Chargers',
    iconClassName: '',
    tooltipContainerClassName: 'tooltip__icon__wrapper',
    buttonId: 'view_chargers',
    customTheme: AddNewBtnTheme,
    buttonText: 'View Chargers',
    btnClassName: 'add__new__btn',
    handleClick: (): void => { },
    buttonVariant: '',
  },
  {
    toolTipText: 'Add Charging Station',
    iconClassName: 'rms__table__dashboard__add__new__btn',
    tooltipContainerClassName: 'tooltip__icon__wrapper',
    buttonId: 'add_new_charging_station',
    customTheme: AddNewBtnTheme,
    buttonText: 'Add Charging Station',
    btnClassName: 'add__new__btn',
    handleClick: (): void => { },
    buttonVariant: '',
  }
];


export const useScreenSize = (): ScreenSizeProp => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = (): void => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenSize;
};

export const checkForScreenResolution = (val): ScreenResolutionProp => {
  const theme = useTheme();
  const result = useMediaQuery(theme.breakpoints.down(val));
  return { result, val };
};

export const useEffectOnce = (effect): void => {
  const [needToCall, setNeedToCall] = useState(false);

  useEffect(() => {
    if (needToCall) {
      effect();
    } else {
      setNeedToCall(true);
    }
  }, [needToCall]);
};

export const useOnUnMountUnsafe = (effect: EffectCallback): void => {
  const initialized = useRef(false)

  useEffect(() => {
    return (): void => {
      if (!initialized.current) {
        initialized.current = true
        effect()
      }
    }

  }, [])
}

export function flattenObject(obj, parentKey = '', separator = '_'): unknown {
  const result = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = parentKey ? `${parentKey}${separator}${key}` : key;

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.assign(result, flattenObject(obj[key], newKey, separator));
      } else {
        result[newKey] = obj[key];
      }
    }
  }
  return result;
}




export const renderHeaderActionButtons = ({
  actionBtns,
}: {
  actionBtns: IHeaderActionButton[];
}): HeaderActionBtn[] => {
  const updatedButtonsArray: HeaderActionBtn[] = actionBtns
    .filter((btn) =>
      HeaderActionBtns?.some((item) => item?.buttonId === btn?.id)
    )
    .map((btn) => {
      const btnProp = HeaderActionBtns?.find(
        (item) => item?.buttonId === btn?.id
      );

      if (btnProp) {
        const updatedBtn: HeaderActionBtn = {
          ...btnProp,
          isAccessible: btn?.isAccessible,
          handleClick: btn?.headerActionBtnClick
            ? // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            (event) => btn.headerActionBtnClick!(event)
            : // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
            (event) => { }, // Default to an empty function if undefined
          badgeCount: btn?.badgeCount
        };

        return updatedBtn;
      }

      return null; // Handle the case where btnProp is not found
    })
    .filter((btn) => btn !== null) as HeaderActionBtn[];

  return updatedButtonsArray;
};

export const createBreadCrumbProps = ({
  breadCrumbProps,
}: {
  breadCrumbProps: IBreadCrumb[];
}): BreadCrumbObject[] => {
  const updatedArray: BreadCrumbObject[] = breadCrumbProps
    .filter((breadCrumb) =>
      BreadCrumbsNavigations?.some((item) => item?.linkBtnId === breadCrumb?.id)
    )
    .map((breadCrumb) => {
      const linkBtnProps = BreadCrumbsNavigations?.find(
        (item) => item?.linkBtnId === breadCrumb?.id
      );
      if (linkBtnProps) {
        if (breadCrumb?.objectType === 'link') {
          const updatedBreadCrumb: BreadCrumbObject = {
            objecType: breadCrumb?.objectType,
            linkBtnProps: {
              ...linkBtnProps,
              link: breadCrumb?.link || linkBtnProps?.link,
              linkBtnState: breadCrumb?.linkBtnState,
              btnText: breadCrumb?.btnText || linkBtnProps?.btnText,
              btnClassName: 'breadCrumb__link__btn',
              showArrow: false,
              handleOnClick: (e) => breadCrumb?.handleOnClick?.(e)
            },
          };
          return updatedBreadCrumb;
        } else {
          const updatedBreadCrumb: BreadCrumbObject = {
            objecType: breadCrumb?.objectType,
            textProps: breadCrumb?.text
          };
          return updatedBreadCrumb;
        }
      }
      return null; // Handle the case where breadCrumbProp is not found
    })
    .filter((breadCrumb) => breadCrumb !== null) as BreadCrumbObject[];

  return updatedArray;
};


export const formatDateInDateTimeFormat = (dateString): string => {
  return moment(dateString).format('MMM D, h:mm:ss a');
};
export const formatDateInDateTimeFormatNotSeconds = (dateString): string => {
  return moment(dateString).format('MMM D, h:mm A');
}

export const formatRelativeTime = (date): string => {
  const isWithinOneWeek = moment(date).isAfter(moment().subtract(1, 'week'));
  return isWithinOneWeek ? `${moment(date).fromNow()} (${moment(date).format('MMM D, h:mm:ss a')})` : moment(date).format('MMM D, h:mm:ss a');
}

export const formatRelativeDateTime = (postDate): string => {
  const now: Date = new Date();
  const secondsAgo: number = Math.floor((now.getTime() - postDate.getTime()) / 1000);

  if (!isNaN(postDate.getTime())) {
    if (secondsAgo <= 0) {
      return 'Refreshed now';
    } else
      if (secondsAgo < 60) {
        return `Refreshed ${secondsAgo} second${secondsAgo !== 1 ? 's' : ''} ago`;
      } else if (secondsAgo < 3600) {
        const minutesAgo = Math.floor(secondsAgo / 60);
        return `Refreshed ${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
      } else if (secondsAgo < 86400) {
        const hoursAgo = Math.floor(secondsAgo / 3600);
        return `Refreshed ${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
      } else if (secondsAgo < 604800) {
        const weeksAgo = Math.floor(secondsAgo / 604800);
        return `Refreshed ${weeksAgo} week${weeksAgo !== 1 ? 's' : ''} ago`;
      } else {
        const daysAgo = Math.floor(secondsAgo / 86400);
        return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
      }
  } else {
    return 'Refreshed now'
  }

}


export const calculateAging = (val): string => {

  const seconds = Number(val);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const d = Math.floor(h / 24);
  const remainingH = h % 24;

  const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
  const hDisplay = remainingH > 0 ? remainingH + (remainingH === 1 ? ' hr, ' : ' hrs, ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' min, ' : ' mins, ') : '0 min';

  const result = (dDisplay + hDisplay + mDisplay).replace(/,\s*$/, '');
  return result;

};


export const isSameDay = (date1, date2): boolean => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

export const formatTime = (format: string, date: Date): string => {
  return format.replace(/%[a-zA-Z]/g, match => {
    switch (match) {
      case '%': return date.toLocaleString('en-US', { month: 'short' });
      case '%M': return date.toLocaleString('en-US', { month: 'short' });
      case '%d': return date.toLocaleString('en-US', { day: 'numeric' });
      case '%Y': return date.toLocaleString('en-US', { year: 'numeric' });
      case '%h': return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      default: return match;
    }
  });
};



export const formatRelativeTimeForTable = (timestamp: Date, options: RelativeTimeForTableOptions = {}): string => {

  const {
    justNowText = 'Just now',
    minuteAgoText = '1 minute ago',
    minutesAgoText = '%d minutes ago',
    todayText = 'Today at %h',
    yesterdayText = 'Yesterday at %h',
    olderDateText = '%M %d at %h',
  } = options;

  const now = new Date();
  const targetDate = new Date(timestamp);

  const diffInSeconds = Math.floor((now.getTime() - targetDate.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return justNowText;
  }

  if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return (minutes === 1) ? minuteAgoText : minutesAgoText.replace('%d', minutes.toString());
  }

  if (isSameDay(targetDate, now)) {
    return formatTime(todayText, targetDate);
  }

  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);
  if (isSameDay(targetDate, yesterday)) {
    return formatTime(yesterdayText, targetDate);
  }

  return formatTime(olderDateText, targetDate);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getAllKeyValues = (obj, key) => {
  let result: string[] = [];

  for (const prop in obj) {
    if (prop === key) {
      result.push(obj[prop]);
    } else if (typeof obj[prop] === 'object' && !Array.isArray(obj[prop])) {
      result = result.concat(getAllKeyValues(obj[prop], key));
    } else if (Array.isArray(obj[prop])) {
      for (const item of obj[prop]) {
        if (typeof item === 'object' && !Array.isArray(item)) {
          result = result.concat(getAllKeyValues(item, key));
        }
      }
    }
  }
  return result;
};



// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const updateValueByKey = (masterObject, key, value, dataType, unit, precision) => {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const updateParameter = (parameters, keyToFind) => {


    for (const parameter of parameters) {
      if (parameter.key === keyToFind) {
        if (value !== null && value !== undefined) {
          parameter.values = precision && dataType === 'Double' ? value?.toFixed(precision) : (dataType === 'Double') && parseInt(value) ? value?.toFixed(2) : value;

          if (unit && unit !== 'NA' && value !== 'NA' && value !== 'Disconnected') {
            parameter.values += `${unit}`;
          }
        } else {
          parameter.values = null;
        }
        return true; // Parameter found and updated
      }
    }
    return false;
  };

  if (masterObject && masterObject.subParameters) {
    const parameterUpdated = updateParameter(masterObject.subParameters, key);

    if (!parameterUpdated) {
      console.error(`Parameter with key '${key}' not found in subParameters.`);
    }
    // masterObject.subParameters.sort((a, b) =>
    //   b.subParameters.length -  a.subParameters.length
    // );
    return masterObject;
  }
  console.error('masterObject does not have subParameters.');

  return masterObject;
};


export const createNestedObject = (visibleNameOfParent: string, param: any, data: any[], parentKey: string) => {
  const subParameterReference = cloneDeep(param?.subParameters);
  const updatedData: unknown[] = [];

  if (Array.isArray(data)) {
    data.map((element, index) => {
      const elementObject: { [key: string]: unknown } = {}
      const elementUpdatedObject: { [key: string]: unknown }[] = []
      let updatedObject: { [key: string]: unknown } = {};
      for (const key in element) {
        if (Object.prototype.hasOwnProperty.call(element, key)) {
          const value = element[key];
          const paramKey = key;
          if (Array.isArray(subParameterReference)) {
            const object = subParameterReference.find(item => item?.key === paramKey);
            if (object) {
              updatedObject = {
                values: value !== null && value !== undefined ? value : null,
                dataType: object.dataType,
                unit: object.unit,
                precision: object.precision,
                numberKeyFormat: object.numberKeyFormat,
                parentNumberKeyFormat: object.parentNumberKeyFormat,
                id: uuidv4() + parentKey,
                key: uuidv4(),
                visibleName: object.visibleName,
                subParameters: object.subParameters
              };
              elementUpdatedObject.push(updatedObject);
            }
          }
        }
      }

      elementObject['visibleName'] = param.visibleName + ' ' + (index + 1)
      elementObject['values'] = param.values
      elementObject['unit'] = param.unit
      elementObject['parentNumberKeyFormat'] = param.numberKeyFormat
      elementObject['numberKeyFormat'] = param.numberKeyFormat
      elementObject['key'] = param.key + index + parentKey
      elementObject['subParameters'] = elementUpdatedObject
      elementObject['id'] = uuidv4()
      updatedData.push(elementObject);
    });
  }
  return updatedData

};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const updateMasterInfo = (masterInfo, newData) => {

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const updateInfoRecursive = (info) => {
    if (info.subParameters && info.subParameters.length > 0) {
      info.subParameters = info.subParameters.sort((a, b) =>
        a.numberKeyFormat.localeCompare(b.numberKeyFormat, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      );

      info.subParameters.forEach((param) => {
        if (param?.dataType?.toString()?.toLowerCase() === 'list') {
          const key = param.key;
          const nestedValueFromApi = newData[key] !== null && newData[key] !== undefined ? newData[key] : null;
          const subParameterObjectCopy = cloneDeep(param)
          const nestedObjectAfterDataMapping = createNestedObject(key, subParameterObjectCopy, nestedValueFromApi, key)
          param.subParameters = nestedObjectAfterDataMapping
        } else {
          const key = param.key;
          const dataType = param.dataType;
          const unit = param.unit;
          const value = newData[key] !== null && newData[key] !== undefined ? newData[key] : null;
          const precision = param.precision;
          info = updateValueByKey(info, key, value, dataType, unit, precision);
          updateInfoRecursive(param); // Recursively handle subParameters
        }


      });
    }
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const updateInfoArray = (infoArray) => {
    if (infoArray && infoArray.length > 0) {
      infoArray = infoArray.sort((a, b) =>
        a.numberKeyFormat.localeCompare(b.numberKeyFormat, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      );

      infoArray.forEach((info) => {
        updateInfoRecursive(info);
      });
    }
  };

  updateInfoArray(masterInfo);
  return masterInfo;
};


export const removeAllFromReferenceData = (refData): unknown => {
  const listData = refData && refData?.length > 0 && refData?.filter(item => item?.label !== 'All')
  return listData
}

export const downloadFile = ({ url }): void => {
  try {
    // Open the file in a new tab or window
    window.open(url, '_blank');
  } catch (error) {
    console.error(error);
  }
};


// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const generateDataPoints = (startDateString, endDateString, selectedOption) => {
  const dataPoints: string[] = [];

  // Convert start_date and end_date to Date objects
  const startDate = new Date(startDateString);
  const endDate: Date = new Date(endDateString); // Explicitly specify the type

  // Calculate the interval based on the selected option
  let interval: number, numPoints: number;

  switch (selectedOption) {
    case 'one_day':
      interval = (endDate.getTime() - startDate.getTime()) / 24;
      numPoints = 24;
      break;

    case 'one_week':
      interval = (endDate.getTime() - startDate.getTime()) / 7;
      numPoints = 7;
      break;

    case 'one_month':
      // eslint-disable-next-line no-case-declarations
      const daysInMonth = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();
      interval = (endDate.getTime() - startDate.getTime()) / daysInMonth;
      numPoints = daysInMonth;
      break;

    case 'custom':
      numPoints = 20; // Default to 20 data points if customNumPoints is not provided
      interval = (endDate.getTime() - startDate.getTime()) / numPoints;
      break;

    default:
      throw new Error('Invalid selected option');
  }

  // Generate data points with equal intervals
  for (let i = 0; i < numPoints; i++) {
    const dataPoint = new Date(startDate.getTime() + i * interval);
    dataPoints.push(dataPoint.toISOString());
  }

  return dataPoints;
}


//TODO: Update & Revert values of Charger Settings parameters by key on changing
export const updateSettingsValueByKey = (settingsData, key, targetKey, targetValue): ChargerMasterInfo[] => {
  for (let i = 0; i < settingsData.length; i++) {
    const currentObject = settingsData[i];

    if (currentObject[key] === targetKey) {
      // Found the target object, update the value
      currentObject.values = targetValue
      if (currentObject.unit && currentObject.unit !== 'NA' && targetValue !== '') {
        currentObject.values += `${currentObject.unit}`;
      }
      return settingsData;
    }

    if (Array.isArray(currentObject.subParameters)) {
      const updatedParameters = updateSettingsValueByKey(currentObject.subParameters, key, targetKey, targetValue);
      // If the target object was found in the nested array, update the current object
      if (updatedParameters !== currentObject.subParameters) {
        currentObject.subParameters = updatedParameters;
        return settingsData;
      }
    }
  }
  // Target object not found
  return settingsData;
}

//TODO: Key Press Validation for Only Characters
export const keyPressValidationForCharacters = (event): void => {
  const keyPressed = event.key;
  // Allow Ctrl+C (copy) and Ctrl+V (paste) and backspace and space
  if (keyPressed === ' ' || keyPressed === 'Backspace' || ((event.ctrlKey || event.metaKey) && (keyPressed.toLowerCase() === 'c' || keyPressed.toLowerCase() === 'v'))) return;
  // Prevent default action if the key is not a letter
  if (!(/^[a-zA-Z]+$/.test(keyPressed))) event.preventDefault();
}

//TODO: Key Press Validation for PINCode
export const keyPressValidationForPINCode = (event): void => {
  const keyPressed = event.key;
  // Allow Ctrl+C (copy) and Ctrl+V (paste) and Backspace
  if (keyPressed === 'Backspace' || ((event.ctrlKey || event.metaKey) && (keyPressed.toLowerCase() === 'c' || keyPressed.toLowerCase() === 'v'))) return;
  // Check if the input is a digit and the length of the input should not exceed 6 digits
  const inputValue = event.target.value + keyPressed;
  if (!(/^\d$/.test(keyPressed)) || inputValue.length > 6) {
    event.preventDefault();
  }
}

export const checkCharactersPaste = (value): string => {
  return /^[a-zA-Z\s]+$/.test(value) ? value : ''
}

export const checkPINCodePaste = (e): string => {
  return (/^\d+$/.test(e?.target?.value) && e?.target?.value?.length < 7) ? e?.target?.value : ''
}

export const formatNumberWithCommas = (numberOrString) => {
  const number = parseFloat(numberOrString);
  if (isNaN(number)) return numberOrString;
  return number.toLocaleString('en-IN');
};

export const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

export const allowedCountries = ['in', 'my', 'sa', 'pt', 'pl', 'th', 'id', 'gb', 'nl','fi']

export const getCurrentCountryCode = () => {
  // Get the time zone offset
  const timeZone = moment.tz.guess();
  console.log('TimeZone:: ',timeZone)
  // Map time zones to country codes
  const timeZoneMap = {
    'Asia/Calcutta': 'in',
    'Asia/Bangkok' : 'th',
    'Asia/Jakarta': 'id',
    'Europe/London': 'gb',
    'Asia/Kuala_Lumpur': 'my',
    'Europe/Amsterdam': 'nl',
    'Asia/Riyadh': 'sa',
    'Europe/Lisbon': 'pt',
    'Europe/Warsaw': 'pl',
    'Europe/Helsinki':'fi',
  };
  // Check if the time zone is in the map
  return timeZoneMap[timeZone] ?? 'in';
}

const globalHooksObject = {
  useScreenSize,
  checkForScreenResolution,
  useEffectOnce,
  flattenObject,
  renderHeaderActionButtons,
  calculateAging,
  formatRelativeTime,
  formatRelativeDateTime,
  formatRelativeTimeForTable,
  removeAllFromReferenceData,
  downloadFile,
  updateMasterInfo,
  updateSettingsValueByKey,
  generateDataPoints,
  getAllKeyValues,
  useOnUnMountUnsafe,
  useIsomorphicLayoutEffect,
  keyPressValidationForCharacters,
  keyPressValidationForPINCode,
  checkCharactersPaste,
  checkPINCodePaste,
  formatNumberWithCommas,
  createBreadCrumbProps,
  allowedCountries,
  getCurrentCountryCode
};

export default globalHooksObject;
